<template>
  <el-tabs class="page-platform-instructions" type="border-card" v-model="tabIndex">
    <el-tab-pane :name="tab.index" v-for="(tab) in tabs" :key="tab.index">
       <span slot="label">
         <div style="padding-left: 28%">
           <i :class="tab.icon" style="font-size: 30px;"></i>
         </div>
        <div style="position: relative; top: -10px;">{{ tab.label }}</div>
      </span>
      <div v-html="tab.content"></div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import {getArticle} from "./articles.api";

const tabs = [
  {
    label: '商户须知',
    index: '0',
    type: '10001',
    content: '',
    icon: 'iconfont icon-caozuoshouce'
  },
  {
    label: '常见问题',
    index: '1',
    type: '10002',
    content: '',
    icon: 'iconfont icon-submit-question'
  },
  {
    label: '活动发布说明',
    index: '2',
    type: '10003',
    content: '',
    icon: 'iconfont icon-huodongfabu'
  },
  {
    label: '商户认证说明',
    index: '3',
    type: '10004',
    content: '',
    icon: 'iconfont icon-ic_line_shgl'
  },
  {
    label: '操作手册',
    index: '4',
    type: '10005',
    content: '',
    icon: 'iconfont icon-caozuoshouce1'
  },
];

export default {
  data() {
    return {
      tabIndex: '0',
    };
  },
  computed: {
    tabs() {
      return tabs;
    }
  },
  watch: {
    tabIndex(index) {
      if (location.hash.includes('type')) {
        location.replace(location.hash.replace(/type=(\d*)/, `type=${index}`));
      } else {
        location.replace(location.hash + `?type=${index}`);
      }
    },
    $route(route) {
      this.load();
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      if (this.$route.query.type) {
        this.tabIndex = this.$route.query.type;
      }

      const tab = tabs[this.tabIndex];

      if (!tab.content) {
        getArticle(tab.type).then(resp => {
          tab.content = resp.content;
          this.$forceUpdate();
        });
      }
    }
  },
};
</script>

<style lang="scss">
@import "./platform-instructions";
</style>
